/* eslint-disable max-depth */
import type { Directive } from 'vue'
import { AsfFormFieldType, AsfFormProps } from '@ui/types'
import type { ComposerTranslation } from 'vue-i18n'

interface AsfFormRefElement extends HTMLElement {
  _scrollToField?: () => void
}
export const componentTypes: Record<AsfFormFieldType, 'asf-input' | 'asf-select' | 'asf-checkbox'> = {
  date: 'asf-input',
  'datetime-local': 'asf-input',
  email: 'asf-input',
  hidden: 'asf-input',
  month: 'asf-input',
  number: 'asf-input',
  password: 'asf-input',
  tel: 'asf-input',
  text: 'asf-input',
  time: 'asf-input',
  week: 'asf-input',
  checkbox: 'asf-checkbox',
  select: 'asf-select'
}

export const getInnerFields = (fields: AsfFormProps['fields'], t: ComposerTranslation) => {
  const inner: AsfFormProps['fields'] = {}

  if (!fields) {
    return inner
  }

  for (const key in fields) {
    const currentField = fields[key]

    if (currentField) {
      const field = { ...currentField }
      inner[key] = field
      const setTranslationForFieldProps = (list: (keyof typeof currentField)[]) => {
        list.forEach((prop) => {
          const value = field[prop]
          if (typeof value === 'string') {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(field as any)[prop] = t(value).toString()
          }
        })
      }
      if (field) {
        setTranslationForFieldProps(['label', 'caption', 'placeholder'])

        if (field.customMessages) {
          field.customMessages = Object.keys(field.customMessages).reduce((customMessages, value) => {
            return {
              ...customMessages,
              [value]: t(field.customMessages?.[value] ?? '', {
                name: field.label,
                minlength: field.minlength,
                maxlength: field.maxlength,
                count: field.minlength
              })
            }
          }, {})
        }
        if (field.options?.length) {
          field.options = field.options.map((option) => {
            return { ...option, label: t(option.label).toString() }
          })
        }
      }
    }
  }
  return inner
}

export const scrollToErrorField: Directive<AsfFormRefElement> = {
  mounted(el) {
    el._scrollToField = () => {
      let elementPosition: ReturnType<typeof getElementCoordinates> = { top: 0, left: 0 }
      const isCheckoutPage = Boolean(el.closest('#checkoutLayout'))
      const offset = isCheckoutPage ? 0 : 80

      nextTick(() => {
        const firstErrorField = el.querySelector('.is-invalid')
        if (firstErrorField) {
          const position = getElementCoordinates(firstErrorField)
          elementPosition = {
            top: Number(position?.top) - offset,
            left: Number(position?.left)
          }

          firstErrorField.querySelector('input')?.focus({ preventScroll: true })
          window.scrollTo(elementPosition)
        }
      })
    }

    el.addEventListener('submit', el._scrollToField)
  },
  unmounted(el) {
    if (el._scrollToField) {
      el.removeEventListener('submit', el._scrollToField)
    }
  }
}
